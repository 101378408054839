<template class="custom-bg">
  <v-container fluid :class="noPadding ? 'pa-0': ''">
    <v-data-table
      v-if="finishInit"
      :key="tableKey"
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 custom-bg"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.action="{ item }">
        <div>{{ $lang.status[item.action] }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.userId="{ item }">
        <div>{{ item.userId ? formatUser(item.userId) : '' }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          outlined
          color="accent"
          class="color-accent-text"
          @click="$emit('restoreHistory', item.resource)"
        >
          {{ $lang.actions.restore }}
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { fetchUserData } from '@/utils/helpers'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      showTypeSelector: false,
      activeOnly: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        { text: this.$lang.header.revision, value: 'id', sortable: false },
        {
          text: this.$lang.header.type,
          align: 'start',
          sortable: false,
          value: 'action'
        },
        { text: this.$lang.labels.createdOn, value: 'createdOn', sortable: false },
        { text: this.$lang.labels.user, value: 'userId', sortable: false },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      roles: [],
      tableKey: 0,
      finishInit: false
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchHistory', { options: this.options })
      },
      deep: true
    },
    items: {
      handler () {
        if (this.items && this.items.items && this.items.items.length > 0) {
          this.init()
        } else {
          this.finishInit = true
        }
      },
      deep: true
    }
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    // MD - 29.03.2024 - Because of change in logic, parent component should call fetchHistory on created
    // this.$emit('fetchHistory', { options: this.options })

    setTimeout(() => this.lock = false, 300)
  },
  methods: {
    async init() {
      const allSavedUsers = localStorage.allUsers ? JSON.parse(localStorage.allUsers).map((x) => x.id) : []
      const itemsUsers = this.items.items.map((x) => x.userId).filter((x) => x !== null)

      const newUsers = allSavedUsers.length > 0 ? itemsUsers.filter((y) => !allSavedUsers.includes(y)) : itemsUsers

      const newUsersUnique = [...new Set(newUsers)]

      if (newUsersUnique.length === 0) {
        this.finishInit = true

        return
      }

      const fetchedUsers = newUsersUnique.map(async (id) => {
        return this.fetchUser(id)
      })

      await Promise.all(fetchedUsers)
        .then((result) => {
          const allSavedUsersFull = localStorage.allUsers ? JSON.parse(localStorage.allUsers) : []

          localStorage.allUsers = JSON.stringify([...allSavedUsersFull, ...result])

          this.finishInit = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    formatUser(id) {
      const allSavedUsers = localStorage.allUsers ? JSON.parse(localStorage.allUsers) : []
      const findById = allSavedUsers.find((user) => user.id === id)

      if (findById) return `${findById.name} - ${findById.status}`

      return ''
    },
    async fetchUser(id) {
      return new Promise((resolve, reject) => {
        fetchUserData(id)
          .then((res) => {
            resolve(res)
          })
          .catch(() => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    }
  }
}
</script>
